@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.App {}

body {
    font-family: 'Montserrat', sans-serif;
    font-size  : 15px;
}

h1,
h2,
h3,
h4 {
    font-weight: 800;
}

h1 {
    font-size: 25px;
}



.align-right {
    text-align: right !important;
}

a,
a:hover {
    color: #E30000;
}

.App-logo {
    height        : 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height      : 100vh;
    display         : flex;
    flex-direction  : column;
    align-items     : center;
    justify-content : center;
    font-size       : calc(10px + 2vmin);
    color           : white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.logo {
    align-items     : flex-end;
    display         : flex;
    padding-top     : 50px;
    padding-bottom  : 50px;
    position        : absolute;
    z-index         : 999;
    width           : 270px;
    background-color: #F8FAFD;
}

.sidebar {
    width   : 310px;
    height  : 100vh;
    position: fixed;
    top     : 0px;
    left    : 0px;
}

nav {
    padding-top     : 140px;
    width           : 310px;
    height          : 100vh;
    left            : 0px;
    top             : 0px;
    background-color: #F8FAFD;
    display         : inline-block;
    overflow        : scroll;
}

nav ul {
    list-style: none;
    margin    : 20px;
    padding   : 0px;
}

nav ul li {
    color         : #142945;
    list-style    : none;
    font-weight   : bold;
    padding-left  : 20px;
    padding-bottom: 30px;
    position      : relative;
}

nav ul li a {
    color          : #142945;
    list-style     : none;
    font-weight    : bold;
    text-decoration: none;
    transition     : 0.2s ease color;
}

nav ul li a:hover {
    text-decoration: none;
}

details.subnav_parent {
    font-weight: bold;
}

summary::-webkit-details-marker,
summary::marker {
    color: transparent;
}

details[open] summary::-webkit-details-marker,
details[open] summary::marker {
    content: "";
}

details.subnav_parent summary {
    position     : relative;
    margin-top   : 30px;
    outline      : none;
    margin-bottom: 30px;
    padding-left : 20px;
}

details .subnav_parent_arrow {
    width              : 22px;
    height             : 22px;
    background-image   : url(./assets/icons/arrow_down.png);
    background-repeat  : no-repeat;
    background-size    : 13px;
    background-position: center;
    display            : inline-block;
    position           : absolute;
    top                : 0px;
    left               : 0px;
    transition         : 0.2s ease transform;
}

details[open] .subnav_parent_arrow {
    transform: rotate(180deg);
}

.red {
    color: #E30000
}

nav ul li.current_page:before {
    width                     : 11px;
    height                    : 11px;
    border-radius             : 100%;
    border-bottom-right-radius: 0px;
    background-color          : #E30000;
    position                  : absolute;
    display                   : block;
    content                   : '';
    left                      : 0px;
    top                       : 4px;
}

.sidebar_right {
    width           : 290px;
    height          : 100vh;
    position        : fixed;
    right           : 0px;
    top             : 0px;
    background-color: #F8FAFD;
    display         : inline-block;
    padding         : 20px;
    overflow        : scroll;
    transition      : margin 0.5s ease;
    margin-right    : 0px;
    z-index         : 9;
}

.content_wrapper {
    width   : calc(100vw - 310px);
    display : inline-block;
    position: absolute;
    top     : 0px;
    left    : 310px;
    padding : 30px;
}

/* Login */

.login_wrapper {
    display        : flex;
    justify-content: center;
    align-items    : center;
    height         : 100vh;
}

.login_container {
    display         : inline-block;
    padding         : 50px;
    width           : 90%;
    max-width       : 600px;
    background-color: #F8FAFD;
}

input {
    font-family: "Montserrat" !important;
}

.MuiTextField-root {
    width        : 100%;
    margin-bottom: 10px;
    font-family  : inherit;
}

.MuiInput-underline:before,
.MuiInput-underline:after {
    border-bottom: 1px solid #142945 !important;
}

.MuiInput-underline.Mui-focused:before {
    border-bottom: 1px solid #142945 !important;
}

.MuiFormLabel-root,
.MuiFormLabel-root.Mui-focused {
    color      : #142945 !important;
    opacity    : 0.57;
    font-family: inherit;
}

.MuiInputLabel-root {
    font-family: inherit !important;
}

.MuiInputBase-root {
    margin-bottom: 15px !important;
}

.MuiInputBase-input {
    color: #142945 !important;
}

.Mui-error,
.MuiFormLabel-root.Mui-focused.Mui-error {
    color  : #E30000 !important;
    opacity: 1 !important;
}

.MuiInput-underline.Mui-error:before,
.MuiInput-underline.Mui-error:after {
    border-bottom: 1px solid #E30000 !important;
}

.MuiInputBase-root {
    width: 100%;
}

.MuiPopover-root {
    z-index    : 9999 !important;
    font-family: "Montserrat" !important;
}

label.MuiFormLabel-root {
    font-size: 13px;
}

.MuiTextField-root label {
    font-size: 15px !important;
}

label {
    margin-bottom: 0px;
}

/* Select */

.MuiSelect-root {
    font-family: "Montserrat" !important;
}

/* Datepicker */

.datepicker_range {
    width   : 100%;
    position: relative;
}

.datepicker_range .rdt {
    width   : 50%;
    display : inline-block;
    position: absolute !important;
}

.datepicker_range .rdt:nth-child(2) {
    left: 50%;
}

.datepicker_range .rdt:nth-child(2) .rdtPicker {
    left: -100%;
}

.datepicker_range input {
    background-color: transparent !important;
    border          : none !important;
    opacity         : 1 !important;
    border-radius   : 0px;
    border-bottom   : 1px solid #142945 !important;
}


/* Buttons */

.button {
    display                   : inline-block;
    border-radius             : 17px;
    cursor                    : pointer;
    border-bottom-right-radius: 0px;
    background-color          : #E30000;
    color                     : #FFFFFF;
    padding                   : 7px 21px;
    transition                : 0.2s ease all;
    border                    : 0px;
    font-size                 : inherit;
    font-family               : inherit;
}

.button.go_back {
    margin-bottom: 20px;
}

.button.save {
    margin-top: 20px;
}

.button:hover {
    box-shadow     : 0px 3px 6px rgba(0, 0, 0, 0.2);
    text-decoration: none;
    color          : white;
}

.go_back_button {
    color       : #142945;
    font-weight : 600;
    padding-left: 35px;
    margin-left : -5px;
    position    : relative;
    transition  : all 0.2s ease;
}

.go_back_button:hover {
    text-decoration: none;
    color          : #E30000;
}

.go_back_button:hover:before {
    background-color: #E30000;
}

.go_back_button:before {
    content            : '';
    width              : 25px;
    height             : 25px;
    display            : block;
    border-radius      : 100px;
    background-color   : #142945;
    position           : absolute;
    left               : 0px;
    top                : -2.5px;
    transition         : all 0.2s ease;
    background-image   : url(./assets/icons/arrow_left_white.png);
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : 11px;
}

.align_right_desktop {
    text-align: right;
}

.add_button {
    color         : #E30000;
    font-weight   : 600;
    padding-bottom: 2px;
    transition    : all 0.2s ease;
    position      : relative;
    margin-left   : 30px;
    opacity       : 0.8;
    display       : inline-block;
    cursor        : pointer;
    border-bottom : 2px solid transparent;
}

.add_button:hover {
    text-decoration: none;
    opacity        : 1;
}

a.add_button:before {
    left: -30px;
    top : 1px;
}

.add_button:before {
    content            : '';
    width              : 20px;
    height             : 20px;
    display            : block;
    border-radius      : 100px;
    background-color   : #E30000;
    position           : absolute;
    left               : -30px;
    top                : 2px;
    transition         : all 0.2s ease;
    background-image   : url(./assets/icons/plus_white.png);
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : 10px;
}


.loading_container {
    width           : 100%;
    height          : 100vh;
    position        : fixed;
    z-index         : 9999;
    background-color: rgba(255, 255, 255, 0.8);
    display         : flex;
    justify-content : center;
    align-items     : center;
    top             : 0px;
    left            : 0px;
}

.loading_container.relative {
    width           : 100%;
    height          : 50vh;
    position        : relative;
    z-index         : 9999;
    background-color: transparent;
    display         : flex;
    justify-content : center;
    align-items     : center;
    top             : auto;
    left            : auto;
}

.loadingSpinner {
    color: #E30000 !important;
}

.checkbox_simple {
    color: #142945;
}

.switch {
    display         : inline-block;
    background-color: transparent;
    border          : 1px solid #142945;
    padding         : 4px 12px;
    color           : rgba(20, 42, 69, 0.57);
    border-radius   : 14px 14px 0px 14px;
    cursor          : pointer;
    transition      : background-color 0.2s ease;
    transition      : padding 0.2s ease;
    margin-right    : 10px;
}

.switch.selected {
    background-image   : url(./assets/icons/check.png);
    background-position: center right 10px;
    background-repeat  : no-repeat;
    background-size    : 16px;
    padding-right      : 30px;
    color              : rgba(20, 42, 69, 1);
    background-color   : rgba(20, 42, 69, 0.36);
}

.root:hover,
.switch:hover {
    background-color: 'transparent',
}

.icon {
    border-radius   : 3;
    width           : 16;
    height          : 16;
    box-shadow      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)';
    background-color: '#f5f8fa';
    background-image: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))';
}

.root.Mui-focusVisible {
    outline       : '2px auto rgba(19,124,189,.6)';
    outline-offset: 2;
}

.checkedIcon {
    background-color: '#137cbd';
    background-image: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))';
}

.checkedIcon:before {
    display: 'block';
    width  : 16;
    height : 16;
    content: '';
}

.checkedIcon input:hover {
    background-color: '#106ba3',
}

.MuiTouchRipple-root {
    display: none !important;
}

.MuiCheckbox-root {
    padding      : 0px !important;
    margin       : 0px !important;
    padding-right: 10px !important;
}

.MuiCheckbox-root:hover {
    background-color: transparent !important;
}

.MuiCheckbox-root.Mui-checked .MuiIconButton-label {
    background-image   : url(./assets/icons/check.png);
    background-position: center 4px;
    background-repeat  : no-repeat;
    background-size    : 10px;
}

.MuiCheckbox-root .MuiIconButton-label {
    border-radius: 5px;
    border       : 1px solid #142945;
    width        : 17px;
    height       : 17px;
}

.MuiFormControlLabel-root {
    margin-left: 0px !important;
}

.MuiFormControlLabel-label {
    font-family: inherit !important;
}

.disabled {
    opacity       : 0.3;
    pointer-events: none;
}

.upload_image_button {
    width              : 100%;
    max-width          : 300px;
    background-color   : #E7EBF0;
    border-radius      : 10px 10px 0px 10px;
    background-image   : url(./assets/icons/upload_image.png);
    background-position: top 30% center;
    background-size    : 70%;
    background-repeat  : no-repeat;
    position           : relative;
    cursor             : pointer;
    transition         : 0.2s ease all;
}

.upload_image_button.pre{
    background-size: 50%;
}

.upload_image_button:before {
    padding-bottom: 120%;
    content       : '';
    display       : block;
}

.upload_image_button:after {
    content    : 'Upload article image';
    display    : block;
    width      : 100%;
    position   : absolute;
    bottom     : 30px;
    font-weight: 900;
    text-align : center;
}

.upload_image_button:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}


/* Table */

.MuiTableRow-root {
    background-color: #FFFFFF;
}

.MuiTableBody-root > .MuiTableRow-root:nth-child(odd) {
    background-color: #F8FAFD;
}

.MuiTableHead-root .MuiTableCell-root {
    font-weight: 800;
}

.MuiTableCell-root {
    border-bottom: none !important;
    font-family  : inherit !important;
}


.MuiTableRow-root.no_background,
.MuiTableBody-root .MuiTableRow-root.no_background:nth-child(odd) {
    background-color: transparent;
    border-top      : 1px solid #E7EBF0;
}

.MuiTableRow-root.no_background:first-child {
    border-top: none;
}



/* Notifications */

.notification_container {
    width              : 500px;
    display            : inline-flex;
    min-height         : 50px;
    padding            : 10px;
    padding-left       : 44px;
    padding-right      : 44px;
    max-width          : 90%;
    border-radius      : 10px 10px 0px 10px;
    color              : #FFFFFF;
    background-color   : #E30000;
    background-image   : url(./assets/icons/x.png);
    background-repeat  : no-repeat;
    background-size    : 14px;
    background-position: left 10px center;
    align-items        : center;
    position           : fixed;
    bottom             : 20px;
    right              : 20px;
    z-index            : 9999;
}

.notification_container.success {
    background-image: url(./assets/icons/success.png);
    background-color: #8BBA62;
}

.notification_close_button {
    width              : 30px;
    height             : 30px;
    position           : absolute;
    right              : 10px;
    top                : 10px;
    background-image   : url(./assets/icons/x.png);
    background-repeat  : no-repeat;
    background-size    : 20px;
    background-position: center;
    cursor             : pointer;
}

.popup_close_button_overlay {
    width           : 100vw;
    height          : 100vh;
    background-color: rgba(0, 0, 0, 0.76);
    display         : inline-flex;
    position        : fixed;
    top             : 0px;
    left            : 0px;
    justify-content : center;
    align-items     : center;
    z-index         : 9999;
    overflow        : scroll;
}

.popup_container {
    display         : inline-block;
    background-color: #FFFFFF;
    padding         : 30px;
    width           : 90%;
    max-width       : 700px;
    z-index         : 99999;
    position        : relative;
    font-size       : 16px;
    font-weight     : normal;
    max-height      : 90%;
    overflow        : scroll;
}

.popup_close_button {
    width              : 30px;
    height             : 30px;
    position           : absolute;
    right              : 20px;
    top                : 20px;
    background-image   : url(./assets/icons/x_dark.png);
    background-repeat  : no-repeat;
    background-size    : 20px;
    background-position: center;
    cursor             : pointer;
}

.small_button {
    display: inline-block;
    cursor : pointer;
    color  : #E30000;
}

.editor {
    background-color: #F8FAFD;
    padding         : 10px;
}

/* accordion */

.accordion {
    width  : 100%;
    display: inline-block;
}

.accordion_title {
    width   : 100%;
    display : inline-block;
    margin  : 10px 0px;
    cursor  : pointer;
    position: relative;
}

.accordion.expanded .accordion_title:before {}

.accordion_arrow {
    width              : 22px;
    height             : 22px;
    background-image   : url(./assets/icons/arrow_down.png);
    background-repeat  : no-repeat;
    background-size    : 13px;
    background-position: center;
    display            : inline-block;
    position           : absolute;
    top                : 0px;
    right              : 0px;
    transition         : 0.2s ease transform;
}

.accordion.expanded>.accordion_title .accordion_arrow,
.accordion_arrow.open {
    transform: rotate(180deg);
}

.ce-block__content {
    max-width: calc(100% - 70px);
}

.ce-toolbar__content {
    max-width: calc(100% - 70px);
}



.article_comment_container {
    display         : block;
    background-color: #F8FAFD;
    border-radius   : 10px 10px 0px 10px;
    margin-bottom   : 10px;
    padding         : 20px;
}

.article_comment_author {
    display      : block;
    text-align   : right;
    margin-top   : 5px;
    margin-bottom: 20px;
}

.article_comment_container.reply {
    margin-left: 20px;
}


.card {
    width           : 100%;
    height          : 100%;
    border-radius   : 10px;
    background-color: #F8FAFD;
    border          : 0px;
    padding-top     : 15px;
    padding-bottom  : 15px;
}

.mobile_header {
    display : none;
    height  : 40px;
    width   : 100%;
    padding : 20px;
    position: fixed;
    z-index : 99999999;
}

.burger_left {
    cursor  : pointer;
    display : none;
    position: relative;
    height  : 24px;
    width   : 30px;
    top     : 20px;
    left    : 20px;
    z-index : 999999999999;
}

.burger_left .burder_line {
    position  : absolute;
    top       : 0px;
    left      : 0px;
    transition: all 0.2s ease;
}

.burger_left .burder_line:nth-child(1) {
    top: 0px
}

.burger_left .burder_line:nth-child(2) {
    top: 9px
}

.burger_left .burder_line:nth-child(3) {
    top: 18px
}

.burger_right {
    background-image   : url('./assets/icons/arrow_down.png');
    background-repeat  : no-repeat;
    background-size    : 15px;
    background-position: center;
    transform          : rotate(90deg);
    width              : 30px;
    height             : 24px;
    display            : none;
    position           : fixed;
    top                : 20px;
    right              : 30px;
    cursor             : pointer;
    z-index            : 99999999;
    transition         : transform 0.2s ease;
}




.burger_left.open .burder_line:nth-child(1) {
    top      : 7px;
    transform: rotate(-45deg);
}

.burger_left.open .burder_line:nth-child(2) {
    display: none;
}

.burger_left.open .burder_line:nth-child(3) {
    top      : 7px;
    transform: rotate(45deg);
}

.burder_line {
    width           : 30px;
    border-radius   : 40px;
    height          : 4px;
    background-color: #E30000;
    display         : block;
    margin-bottom   : 4px;
}


.middle_container {
    width      : calc(100% - 280px);
    margin-left: 0px;
}

/* Statistics Graph */

.graph_container {
    background-color          : #F8FAFD;
    border-radius             : 20px;
    border-bottom-right-radius: 0px;
    padding                   : 30px !important;
}

.graph_container .tooltip {
    color: #E30000;
}

.graph_container * {
    font-family: 'Montserrat', sans-serif !important;
}

.graph_container strong {
    font-weight: 900 !important;
}

.graph_container #top-container {
    padding-bottom: 20px;
}

.graph_container #top-container #top-left {
    width: 0px !important;
}

div[role="tooltip"] {
    color: #E30000;
}


@media (max-width: 1300px) {

    .sidebar {
        width      : 310px;
        max-width  : 100vw;
        height     : 100vh;
        position   : fixed;
        margin-left: -310px;
        transition : margin 0.5s ease;
        z-index    : 999999999;
    }

    .sidebar.open {
        margin-left: 0px;
    }

    .sidebar_right {
        margin-right: -100%;
        width       : 100%;
    }

    .sidebar_right.open {
        margin-right: 0px;
    }

    .burger_right {
        display: block;
    }

    .burger_right.open {
        transform: rotate(-90deg);
    }

    .content_wrapper {
        width      : 100%;
        display    : inline-block;
        position   : absolute;
        top        : 0px;
        left       : 0px;
        padding    : 0px;
        padding-top: 60px;
    }

    .burger_left {
        position: fixed;
        top     : 30px;
        left    : 30px;
        display : inline-block;
    }

    .row>div {
        margin-bottom: 30px;
    }

    .mobile_header {
        display: block;
    }

    .middle_container {
        width: 100%;
    }

    .go_back_button {
        top : 20px;
        left: 20px;
    }

}

@media (max-width: 767px) {
    .align_right_desktop {
        text-align: left;
    }
}


/* Info */
/* .info_wrapper {
    display    : inline-block;
    position   : relative;
    width      : 25px;
    height     : 25px;
} */

.info_button {
    width                     : 25px;
    height                    : 25px;
    border-radius             : 30px;
    border-bottom-right-radius: 0px;
    background-color          : #142945;
    color                     : white;
    font-weight               : bold;
    display                   : inline-flex;
    text-align                : center;
    font-size                 : 15px;
    cursor                    : pointer;
    z-index                   : 100;
    position                  : relative;
    top                       : 0px;
    left                      : 0px;
}

.info_button:before {
    content   : 'i';
    color     : white;
    text-align: center;
    display   : block;
    position  : absolute;
    top       : 4px;
    left      : 10px;
}

/* 
.info_container {
    background-color          : #F8FAFD;
    position                  : absolute;
    color                     : #142945;
    left                      : 50%;
    top                       : 30px;
    font-size                 : 0.875rem;
    font-weight               : normal;
    padding                   : 10px 15px;
    width                     : 300px;
    display                   : inline-block;
    text-align                : left;
    border-radius             : 10px;
    border-bottom-right-radius: 0px;
    transform                 : translateX(-50%);
    z-index                   : 99;
}

.info_container_circle {
    width           : 70px;
    height          : 70px;
    border-radius   : 70px;
    position        : absolute;
    left            : 150px;
    background-color: #F8FAFD;
    top             : 0px;
    z-index         : -1;
    transform       : translate(-50%, -50%);
} */